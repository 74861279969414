import { useState } from "react";
import { useLocation } from 'react-router-dom'
import { useNavigate, useParams } from "react-router-dom";
import { 
    ItemWrapper, 
    Label, 
    Input, 
    Container, 
    Button,
    Wrapper,
    Informative,
    TicketInfo,
    Description,
    Price, 
    ListWrapper
} from "./styles"
import client from "../../../../assets/service/client";
import { useQuery } from "react-query";

const UpdatePromoForm = (props) => {
    const location = useLocation()
    const { eventId, promoId } = useParams();

    const { state: { promo } } = location;
    const [code, setCode] = useState(promo.code);
    const [limit, setLimit] = useState(promo.limit);
    const [activeTicketSpecs, setActiveTicketSpecs] = useState([])
    
    const navigate = useNavigate();

    const onSubmit = async () => {
       console.log(activeTicketSpecs)
        await client.post("promo/update", {
            promoId,
            code,
            limit,
            ticketSpecIds: activeTicketSpecs
        })
        navigate(-1)
    }

    const getTicketSpecsByEventIdAndPromoId = async () => {
        return await client.get(`/promo/${promoId}/event/${eventId}/ticketSpecs`)
        .then(res => {
            let currentActiveTicketSpecsByPromo = [];
            for (let t of res.data.ticketSpecs) {
                console.log(t)
                if (t.associated) currentActiveTicketSpecsByPromo.push(parseInt(t.id))
            }
            setActiveTicketSpecs(currentActiveTicketSpecsByPromo);
            return res.data.ticketSpecs;
        })
        // .catch(err => err)
    }

    const { data: ticketSpecsByEvent } = useQuery('ticketSpecsByEventId', getTicketSpecsByEventIdAndPromoId);

    const checked = (event) => {
        const ticketSpecId = parseInt(event.target.value);
        const { checked } = event.target;
        if (checked) {
            setActiveTicketSpecs([...activeTicketSpecs, ticketSpecId])
        } else {
            setActiveTicketSpecs(activeTicketSpecs.filter(tid => tid != ticketSpecId))
        }
    }

    return (
        <Container>
            <ItemWrapper>
                <Label>Código*</Label>
                <Input value={code} onChange={(event) => setCode(event.target.value)} />
            </ItemWrapper>
            <ItemWrapper>
                <Label>Limite</Label>
                <Input value={limit ? limit : ""} onChange={(event) => setLimit(event.target.value)} />
            </ItemWrapper>
            {
                ticketSpecsByEvent && ticketSpecsByEvent.length && ticketSpecsByEvent.map(t => {
                    return (
                        <Wrapper key={t.id}>
                            <Informative>
                                <TicketInfo>
                                    <Description>{t.description}</Description>
                                    <Price>R${t.price}</Price>
                                </TicketInfo>
                                {
                                t.promo ? <span style={{fontSize: "0.75em", color: "gray"}}>cód. promo: <b>{t.promo}</b></span> :
                                <input type="checkbox" checked={activeTicketSpecs.includes(parseInt(t.id))} value={t.id} onChange={(event) => checked(event)}></input>
                                }
                            </Informative>
                        </Wrapper>
                    )
                })
            }
            <div style={{minHeight: '60px', height: 'calc(8% + 10px)'}} />
            <Button onClick={onSubmit}>Salvar</Button>
        </Container>
    )
}

export default UpdatePromoForm