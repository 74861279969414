import client from "../../../assets/service/client";
import { useQuery, useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";

import {
    Container, 
    ListWrapper,
    Wrapper,
    Informative,
    TicketInfo,
    Description,
    Price,
    Button,
    Config,
    Item,
    ButtonWrapper,
    ConfirmationWrapper,
    ButtonLink,
} from './styles'

import { useState } from "react";
import Loader from "../../../assets/components/loader/Loader";

const Control = () => {
    const { id } = useParams();
    const [activeTicketSpecs, setActiveTicketSpecs] = useState([])

    const getTicketSpecsByEventId = async () => {
        return await client.get(`/event/${id}/ticketSpecs`)
        .then(res => {
            let currentActiveTicketSpecs = [];
            for (let t of res.data.ticketSpecs) {
                if (t.active) currentActiveTicketSpecs.push(parseInt(t.id))
            }
            setActiveTicketSpecs(currentActiveTicketSpecs);
            return res.data.ticketSpecs;
        })
        .catch(err => err)
    }

    const setBatches = async ({eventId, ticketSpecIds}) => {
        return await client.post(`/ticket/active`, {
            eventId,
            ticketSpecIds
        })
        .then(res => {
            return res.data;
        })
    }

    const checked = (event) => {
        const ticketSpecId = parseInt(event.target.value);
        const { checked } = event.target;
        if (checked) {
            setActiveTicketSpecs([...activeTicketSpecs, ticketSpecId])
        } else {
            setActiveTicketSpecs(activeTicketSpecs.filter(tid => tid != ticketSpecId))
        }
    }

    const { data: ticketSpecs } = useQuery('ticketSpecsByEventId', getTicketSpecsByEventId);
    const {isIdle, isLoading, isError, isSuccess, mutate} = useMutation(setBatches);

    return (
        <Container>
            <Config>
                <Item active={true} onClick={ () => {}}>Lotes</Item>
                {/* <Item active={false} onClick={ () => {} }>Promos</Item> */}
            </Config>
            <ListWrapper>
                {
                    ticketSpecs && ticketSpecs.map(t => {
                        return (
                            <Wrapper key={t.id}>
                                <Informative>
                                    <TicketInfo to={`/events/${id}/control/update/${t.id}`} state={{ ticket: t}}>
                                        <Description>{t.description}</Description>
                                        <Price>R${t.price}</Price>
                                    </TicketInfo>
                                    {
                                    t.promo ? <span style={{fontSize: "0.75em", color: "gray"}}>cód. promo: <b>{t.promo}</b></span> :
                                    <input type="checkbox" checked={activeTicketSpecs.includes(parseInt(t.id))} value={t.id} onChange={(event) => checked(event)}></input>
                                    }
                                </Informative>
                            </Wrapper>
                        )
                    })
                }
            </ListWrapper>
            {
            isIdle ? 
            <ButtonWrapper>
                <ButtonLink to={`/events/${id}/control/new`}><span style={{fontSize: '2em', fontWeight: "bold"}}>+</span></ButtonLink>
                <Button onClick={() => mutate({eventId: id, ticketSpecIds: activeTicketSpecs})}>Salvar</Button>
            </ButtonWrapper> :
            isLoading ? <Loader scale={0.4} /> :
            isError ? <>algo deu errado</> :
            isSuccess && 
            <ConfirmationWrapper>
                <span>Lotes atualizados com sucesso</span> 
                <Link to={`/events/${id}`}>Voltar</Link>
            </ConfirmationWrapper>
            }
        </Container>
    )

}

export default Control