import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import client from "../../../assets/service/client";
import { Container, Config, Item, ListContainer, ListItem, ListPosition, ListInformation } from "./styles";

import { AiOutlineSortAscending, AiOutlineFieldTime } from 'react-icons/ai'
import { FaUserCheck, FaUserTimes } from 'react-icons/fa'
import { BsCheck2 } from 'react-icons/bs'

const Attendee = (props) => {
    let t = props.ticket;
    const { id } = useParams();
    let [confirm, setConfirm] = useState(false)

    const validateTicket = async(secret) => {
        client.post('/validate/ticket', {
            secret,
            alias: 'reverse'
          })
        var link = document.getElementById(secret);
        link.style.display = 'none';
    }

    return (
        <ListItem key={t.id}>
            <ListPosition>
                <span>{props.position}.</span>
                    { !t.validated && !confirm && <FaUserCheck id={t.secret} onClick={() => setConfirm(true)}/> }
                    { 
                        !t.validated && confirm && 
                        <div style={{fontSize: "0.5em"}} onClick={() => validateTicket(t.secret)} id={t.secret}> 
                            CONFIRMAR
                            <BsCheck2 /> 
                        </div> 
                    }
            </ListPosition>

            <ListInformation>
            <span>{t.user.firstName} {t.user.lastName}</span> 
            <span style={{fontSize: "0.8rem", fontWeight: 300}}>{t.user.email}</span> 
            <span style={{fontSize: "0.8rem", fontWeight: 500}}>{t.ticketSpec.description}</span> 
            </ListInformation>
        </ListItem>
    )
}

const List = () => {
    const { id } = useParams();
    const [orderBy, setOrderBy] = useState("AZ")

    const getTicketsByEventId = async () => {
        return await client.get(`/event/${id}/mintedTickets`)
        .then(res => {
            return res.data.tickets;
        })
        .catch(err => err)
    }

    const parse = (tickets) => {
        console.log(tickets)
        if (!orderBy || orderBy == "AZ") return tickets
        if (orderBy == "date") return tickets.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        return tickets
    }

    const { data: tickets } = useQuery('ticketsByEventId', getTicketsByEventId);

    return (
        <Container>
        <Config>
            <Item active={orderBy == "date"} onClick={ () => {setOrderBy("date")}}><AiOutlineFieldTime /></Item>
            <Item active={orderBy == "AZ"} onClick={ () => {setOrderBy("AZ")} }><AiOutlineSortAscending /></Item>
        </Config>
        <ListContainer>
        {
            tickets && parse(tickets).map((t, i) => {
                console.log(t)
                return (
                    <Attendee key={t.id} position={i+1} ticket={t}/>
                )
            })
        }
        </ListContainer>
        </Container>
    )
}

export default List